import axios from "axios";
import { authHeader } from "../utils/requestHeaders";

const apiUrl = process.env.REACT_APP_API_URL;

export const newSubscribe = (phoneNumber, priceId) => {
  return axios.post(
    apiUrl + "/subscription/initiate/" + priceId,
    { phoneNumber: phoneNumber },
    {
      headers: authHeader(),
    }
  );
};

export const verifySubscribe = (subscribeRequestId, otp, priceId) => {
  var request = axios.patch(
    apiUrl + "/subscription/subscribe",
    { subscribeRequestId: subscribeRequestId, otp: otp, priceId: priceId },
    {
      headers: authHeader(),
    }
  );

  console.log("request: ", request);

  return request;
};

export const checkSubscription = (phoneNumber, clickId) => {
  var request = axios.get(
    apiUrl + `/subscription/check/${phoneNumber}?clickId=${clickId}`,
    {
      headers: authHeader(),
    }
  );

  console.log("request: ", request);

  return request;
};

export const cancelSubscription = (phoneNumber) => {
  return axios.delete(apiUrl + "/subscription/cancel/" + phoneNumber, {
    headers: authHeader(),
  });
};
