import * as subscribeService from "../../services/subscribe.service";

const checkSubscriptionAction = (payload) => ({
  type: "SUBSCRIPTION",
  payload,
});

const NotFoundAction = () => ({ type: "SUBSCRIPTION_NOT_FOUND" });

const resetSubscriptionAction = () => ({ type: "LOGOUT" });

export const checkSubscription = (phoneNumber, clickId) => (dispatch) => {
  subscribeService
    .checkSubscription(phoneNumber, clickId)
    .then((response) => {
      if (response.data.data.active) {
        localStorage.setItem("phoneNumber", phoneNumber);
        dispatch(checkSubscriptionAction(response.data.data.subscription));
      } else dispatch(NotFoundAction());
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 404) {
          dispatch(NotFoundAction());
        }
      }
    });
};

export const logout = () => (dispatch) => {
  dispatch(resetSubscriptionAction());
  localStorage.clear();
};
